import { useState } from "react";
import { Card, Button } from "@pongo";

import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { EstateAgentThirdfortModal } from "../Modals/EstateAgentThirdfortModal";
import { Table } from "../Table/Table";

export const EstateAgentThirdfortBlock = () => {
  const { estateAgent } = useEstateAgentContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Card
        title="Thirdfort"
        titleRightContent={
          !estateAgent?.thirdfort ? undefined : (
            <button
              className="text-brand-heavy-warmth-25 underline"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Edit
            </button>
          )
        }
      >
        {!estateAgent?.thirdfort ? (
          <div className="flex justify-center">
            <Button variant="secondary" onClick={() => setIsModalOpen(true)}>
              Add details
            </Button>
          </div>
        ) : (
          <>
            <Table
              label="Conducts Thirdfort checks on sellers"
              value={
                estateAgent?.thirdfort?.requestsSellerThirdfortChecks
                  ? "Yes"
                  : "No"
              }
            />
            {estateAgent?.thirdfort?.thirdfortRequesterEmails?.map(
              (email, index) => (
                <Table
                  key={index}
                  label={`Email address ${index + 1}`}
                  value={email}
                />
              ),
            )}
          </>
        )}
      </Card>

      {isModalOpen && (
        <EstateAgentThirdfortModal onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};
