import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@pongo";

import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { AddressBlock } from "../../components/UserDetailsBlocks/AddressBlock";
import { PersonalDetailsBlock } from "../../components/UserDetailsBlocks/PersonalDetailsBlock";
import { ApiController } from "../../../service/ApiController";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { FetchUserResponse } from "../../../service/ApiController/fetchUser";

import { Address } from "./types";

export const UserDetails = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[3];

  const [user, setUser] = useState<FetchUserResponse>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getUser() {
      setError("");

      try {
        const userRes = await ApiController.fetchUser(id);
        setUser(userRes);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          setError("There was an error while fetching the transaction details");
        }
      }
    }

    setLoading(true);
    getUser();
    setLoading(false);
  }, [id]);

  return (
    <>
      <Breadcrumbs items={[{ name: id }]} />

      <AdminTitle title={`${user?.first_name} ${user?.last_name}`} />

      {loading && <FullPageLoader />}

      {error && (
        <Typography variant={"lg"} weight={"bold"} type={"p"}>
          {error}
        </Typography>
      )}

      {!loading && !error && user && (
        <div className="flex flex-col space-y-5">
          <PersonalDetailsBlock user={user} />
          <AddressBlock
            address={user?.profile?.correspondence_address as Address}
          />
        </div>
      )}
    </>
  );
};
