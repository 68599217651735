import {
  CompanySellerState,
  ManualTransactionState,
  PersonalDetailsWithAddressState,
  TransactionsGroupState,
} from "@schema";
import { Product } from "@shared/types";
import { FetchUserResponse } from "../../../service/ApiController/fetchUser";
import { Company } from "../../../types";
import { CreateManualTransactionsRequest } from "../../service/AdminApiController/createManualTransactions";
import { initialPersonalDetailsStateObj } from "../../../store/initialDetails";

export const createRepresentativeValues = (
  user: FetchUserResponse,
): PersonalDetailsWithAddressState => ({
  firstName: user.first_name || "",
  middleName: user.middle_name || "",
  lastName: user.last_name || "",
  email: user.email,
  mobileNumber: user.profile?.phone_number || "",
  // FIXME: This is temporary. It will be fixed after country_iso_2 is required in the backend
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  correspondenceAddress:
    user.profile?.correspondence_address ||
    initialPersonalDetailsStateObj.correspondenceAddress,
  personId: user._id || "",
  termsAndConditions: true,
  primaryCustomer: true,
});

export const createCompanySellerValues = (
  company: Company,
): CompanySellerState => ({
  name: company.name,
  companyNumber: company.company_number,
  address: company.address,
  companyId: company._id,
});

export const isEmptyTransaction = (transaction: ManualTransactionState) => {
  return (
    !transaction.property ||
    !transaction.property.askingPrice ||
    Object.values(transaction.property.propertyAddress).every((value) => !value)
  );
};

export const transformItemsData = (products: Product[]) =>
  products.map((product) => ({
    product_name: product.name,
    product_price: 0,
  }));

export const transformTransactionData = (
  manualTransactions: ManualTransactionState[],
  companySeller?: CompanySellerState,
): CreateManualTransactionsRequest["transactions"] => {
  return manualTransactions.map((transaction) => ({
    property: {
      ...transaction.property.propertyAddress,
      tenure: transaction.property.tenureOption || "",
    },
    has_mortgage: false,
    onward_purchase: false,
    asking_price: parseFloat(
      transaction.property.askingPrice.replace(/,/g, ""),
    ),
    type: "development",
    terms_and_conditions: true,
    company_seller: companySeller?.companyId || "",
  }));
};

export const transformSellerData = (
  representative: PersonalDetailsWithAddressState,
  representativeId: string,
) => {
  return {
    user: {
      _id: representativeId,
      email: representative.email,
      first_name: representative.firstName,
      last_name: representative.lastName,
      middle_name: representative.middleName,
      phone_number: representative.mobileNumber,
      correspondence_address: representative.correspondenceAddress,
    },
    customer_type: "representative" as "representative" | "individual_owner",
  };
};

export const createManualTransactionsRequestBody = (
  manualTransactions: ManualTransactionState[],
  companySeller: CompanySellerState,
  representative: PersonalDetailsWithAddressState,
  representativeId: string,
  transactionsGroup: TransactionsGroupState,
  stripeTransactionId: string | undefined,
  products: Product[],
  companySellerId: string,
): CreateManualTransactionsRequest => {
  return {
    transactions: transformTransactionData(manualTransactions, companySeller),
    primary_seller: transformSellerData(representative, representativeId),
    group: transactionsGroup,
    stripe_transaction_id: stripeTransactionId || undefined,
    items: transformItemsData(products),
    company_seller: companySellerId,
  };
};
