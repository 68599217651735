import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { format } from "date-fns";

import { Card } from "@/components/Card/Card";
import { Button } from "@/components/Button/Button";
import ErrorText from "@/foundation/Typography/ErrorText";
import Typography from "@/foundation/Typography/Typography";
import { useTransactionContext } from "../../context/TransactionProvider";
import { TransactionEvents } from "../../../types";
import { ApiController } from "../../../service/ApiController";

export const ThirdfortSellersEstateAgentRequestsChecks = () => {
  const {
    transactionEvents,
    getTransactionEvents,
    transaction,
    error: transactionError,
  } = useTransactionContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [estateAgentEmailSentEvents, setEstateAgentEmailSentEvents] =
    useState<TransactionEvents>([]);

  useEffect(() => {
    if (transactionEvents) {
      const filteredEvents = transactionEvents.filter(
        (x) => x.event_name === "thirdfort_requester_email_sent",
      );

      setEstateAgentEmailSentEvents(filteredEvents);
    }
  }, [transactionEvents]);

  const onSendEstateAgentEmail = async (transactionId: string) => {
    try {
      setLoading(true);
      setError("");

      await ApiController.sendEstateAgentThirdfortEmail(transactionId);
      await getTransactionEvents!();
    } catch (error) {
      let errorMessage = "";

      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data?.message || error.message;
      }

      setError(errorMessage || "There was an error sending the email");
    } finally {
      setLoading(false);
    }
  };

  const getDatesAsString = (events: TransactionEvents): string => {
    return events
      .map((x) => format(new Date(x.date_time), "dd/MM/yyyy"))
      .join(", ");
  };

  return (
    <Card
      titleBackgroundColor="brand-heavy-warmth-25"
      titleColor="brand-heavy-teal-100"
    >
      <div className="flex flex-col items-center">
        <Typography className="mb-[24px] max-w-[700px] text-center">
          The estate agent assigned to this transaction conducts Thirdfort
          checks on sellers. Send them an email to request that they forward
          these checks to the assigned solicitor.
        </Typography>

        {estateAgentEmailSentEvents.length ? (
          <>
            <Typography>An email has been sent to the estate agent.</Typography>
            <Typography className="mb-[16px]">{`Email previously sent on: ${getDatesAsString(estateAgentEmailSentEvents)}`}</Typography>
          </>
        ) : null}

        <Button
          variant="primary"
          onClick={() => onSendEstateAgentEmail(transaction!._id)}
          disabled={loading}
          className="w-full md:w-[400px]"
        >
          {estateAgentEmailSentEvents?.length
            ? "Request checks from Estate Agent again"
            : "Request checks from Estate Agent"}
        </Button>

        {error && <ErrorText>{error || transactionError}</ErrorText>}
      </div>
    </Card>
  );
};
