import { PersonalDetailsWithAddressState } from "@schema";
import { UpdateUserRequest } from "../../service/ApiController/updateUser";

export const transformUpdatePrimaryCustomerForUpdateCustomerApi = (
  primaryCustomer: PersonalDetailsWithAddressState,
): UpdateUserRequest => ({
  email: primaryCustomer.email,
  first_name: primaryCustomer.firstName,
  last_name: primaryCustomer.lastName,
  middle_name: primaryCustomer.middleName,
  phone_number: primaryCustomer.mobileNumber,
  correspondence_address: primaryCustomer.correspondenceAddress,
});
