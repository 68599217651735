import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { FetchUserResponse } from "../../../service/ApiController/fetchUser";

interface PersonalDetailsBlockProps {
  user: FetchUserResponse;
}

export const PersonalDetailsBlock = ({ user }: PersonalDetailsBlockProps) => {
  const [personalDetails, setPersonalDetails] = useState<TableProps[]>([]);

  useEffect(() => {
    setPersonalDetails([
      { label: "First Name", value: user.first_name },
      { label: "Middle Name", value: user.middle_name },
      { label: "Last Name", value: user.last_name },
      { label: "Email", value: user.email },
      { label: "Phone Number", value: user.profile?.phone_number },
    ]);
  }, [user]);

  return (
    <Card
      title="Personal Information"
      titleBackgroundColor="brand-heavy-warmth-25"
      titleColor="brand-heavy-teal-100"
    >
      <>
        {personalDetails.map(({ label, value }) => (
          <Table key={`personal-details${label}`} label={label} value={value} />
        ))}
      </>
    </Card>
  );
};
