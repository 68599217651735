import { useEffect, useState } from "react";
import { Button, Typography } from "@pongo";

import { useTransactionContext } from "../../context/TransactionProvider";
import { ThirdfortSellersEstateAgentRequestsChecks } from "./ThirdfortSellersEstateAgentRequestsChecks";
import { ThirdfortTable } from "./ThirdfortTable";
import { ThirdfortBlock } from "../TransactionBlocks/ThirdfortBlock";

/**
 * @returns current component for Thirdfort tabs.
 * Based on the date of the transaction, it will render the appropriate version of the ThirdfortSellers component.
 */
export const ThirdfortSellersV2 = () => {
  const { transaction } = useTransactionContext();
  const [hasAssignedSellerSolicitor, setHasAssignedSellerSolicitor] =
    useState(false);

  const [
    estateAgentRequestsThirdfortChecks,
    setEstateAgentRequestsThirdfortChecks,
  ] = useState(false);

  useEffect(() => {
    if (transaction) {
      setEstateAgentRequestsThirdfortChecks(
        !!transaction?.estate_agent?.thirdfort?.requestsSellerThirdfortChecks,
      );

      setHasAssignedSellerSolicitor(!!transaction.lawyer_group?.seller);
    }
  }, [transaction]);

  return (
    <>
      {estateAgentRequestsThirdfortChecks && (
        <ThirdfortSellersEstateAgentRequestsChecks />
      )}

      <ThirdfortTable
        cardTitle="Sellers"
        actionButtonsDisabled={!hasAssignedSellerSolicitor}
        persons={transaction!.sellers.map((s) => ({
          first_name: s.user.first_name,
          last_name: s.user.last_name,
          email: s.user.email,
          phone_number:
            s.user.phone_number || s.user.profile?.phone_number || "",
          person_type: "seller",
        }))}
      />
    </>
  );
};

/**
 *
 * @returns legacy component for Thirdfort tabs.
 * Based on the date of the transaction, it will render the appropriate version of the ThirdfortSellers component.
 */
export const ThirdfortSellersV1 = () => {
  const { transaction } = useTransactionContext();

  return (
    transaction &&
    transaction.sellers.map((seller, index) => (
      <ThirdfortBlock
        key={`thirdfort-seller-${index}`}
        title={`Seller ${index + 1}`}
        address={transaction.property_id.address}
        profile={seller.user}
        solicitorEmail={
          transaction.lawyer_group?.seller.solicitor.email.toLowerCase() || ""
        }
        thirdfortEmail={
          transaction.lawyer_group?.buyer?.thirdfortRecipientEmails
            ?.map((email) => email.toLowerCase())
            .join(", ") || ""
        }
        leftLabel={`Seller ${index + 1} of ${transaction.sellers.length}`}
      />
    ))
  );
};

interface ThirdfortSellersProps {
  showV2Sellers: boolean;
}

export const ThirdfortSellers = ({ showV2Sellers }: ThirdfortSellersProps) => {
  const [showV1Sellers, setShowV1Sellers] = useState(false);

  return (
    <>
      {showV2Sellers && <ThirdfortSellersV2 />}
      {showV1Sellers && <ThirdfortSellersV1 />}

      <div className="flex justify-end">
        <Button
          variant="link"
          onClick={() => setShowV1Sellers((prev) => !prev)}
          className="px-0 w-auto mt-[-24px]"
        >
          <Typography
            variant="sm"
            type="span"
            className="text-brand-heavy-teal-100"
          >
            {showV1Sellers ? "Hide" : "Show"} Full Details
          </Typography>
        </Button>
      </div>
    </>
  );
};
